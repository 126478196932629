import * as THREE from 'three';
import React, { useRef, useEffect } from 'react';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OrbitControls } from 'three-stdlib';
import { TLM } from './utils';

type ThreeSceneProps = {
  data: {
    qx: TLM;
    qy: TLM;
    qz: TLM;
    qs: TLM;
  };
};

const ThreeScene: React.FC<ThreeSceneProps> = ({ data }) => {
  const width = 1500;
  const height = 1150;
  const mountRef = useRef<HTMLDivElement>(null);
  const objectRef = useRef<THREE.Object3D | null>(null);

  useEffect(() => {
    if (!mountRef.current) return;

    // Scene setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(40, width / height, 0.1, 200);
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(width, height);
    renderer.setClearColor(0x124313, 0);
    mountRef.current.appendChild(renderer.domElement);

    // OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;

    // Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff);
    scene.add(ambientLight);

    // Loaders
    const mtlLoader = new MTLLoader();
    mtlLoader.load(
      'public/plugins/ssl-escapadegncflow-panel/img/Spacecraft.mtl',
      (materials) => {
        materials.preload();

        // OBJ Loader
        const loader = new OBJLoader();
        loader.setMaterials(materials);
        loader.load(
          'public/plugins/ssl-escapadegncflow-panel/img/Spacecraft.obj',
          (object) => {
            console.log('load finished');
            object.scale.set(0.1, 0.1, 0.1);
            object.position.set(0, 0, 0);
            scene.add(object);

            // Store object reference
            objectRef.current = object;

            // Adjust camera position
            //const box = new THREE.Box3().setFromObject(object);
            // const size = box.getSize(new THREE.Vector3()).length();
            // const center = box.getCenter(new THREE.Vector3());
            camera.position.set(0,0,0.6);

            controls.update();

            // Arrow helpers (Sun, Earth, Mars)
            const origin = new THREE.Vector3(0, 0, 0);
            const length = 2;

            const sunDir = new THREE.Vector3(1, 2, 0).normalize();
            const sunArrowHelper = new THREE.ArrowHelper(sunDir, origin, length, 0xffff00);

            const earthDir = new THREE.Vector3(-1, 2, 0).normalize();
            const earthArrowHelper = new THREE.ArrowHelper(earthDir, origin, length, 0x00ffff);

            const marsDir = new THREE.Vector3(-2, -2, -1).normalize();
            const marsArrowHelper = new THREE.ArrowHelper(marsDir, origin, length, 0xff0000);

            object.add(sunArrowHelper);
            object.add(earthArrowHelper);
            object.add(marsArrowHelper);
          },
          (xhr) => {
            //console.log((xhr.loaded / xhr.total * 100) + '% loaded');
          },
          (error) => {
            console.error('An error happened during loading: ', error);
          }
        );
      }
    );

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      // Apply quaternion rotation to the object
      if (objectRef.current) {
        const quaternion = new THREE.Quaternion(parseFloat(data.qx.telemetry), parseFloat(data.qy.telemetry), parseFloat(data.qz.telemetry), parseFloat(data.qs.telemetry));
        //const quaternion = new THREE.Quaternion(1, 0, 0, Math.PI / 2);
        objectRef.current.setRotationFromQuaternion(quaternion);
      }

      controls.update(); 
      renderer.render(scene, camera);
    };

    animate();
  }, []); // Empty dependency array to avoid re-initializing the scene

  return <div ref={mountRef} />;
};

export default ThreeScene;
