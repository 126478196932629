import React, {useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, { ReactFlowInstance, ReactFlowProvider} from 'reactflow';
import 'reactflow/dist/style.css';
import { Field, ProcessedData, TLM, nameToMne } from './utils';
import Panel from '../module/Panel';
import Textbox from './Textbox';
import CustomEdge from './CustomEdge';
import SpacecraftModel from './SpacecraftModel';
import Chart from './PlotlyChart'
import { css } from '@emotion/css';
import VModeBox from './VModeBox';
import HTextBox from './HTextbox';
import { useTimeRangeContext } from './TimeRangeContext';
import SummaryBox1 from './SummaryBox1';
import SummaryBox2 from './SummaryBox2';

const reactAppWrapper = css` 

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.react-flow__node {
  z-index: 10 !important;
}

.react-flow__edges {
  z-index: 9 !important;
}

.react-flow__node-escCommPanel{
  z-index: 5 !important;
}

.react-flow__edge-span{
  z-index: 3 !important;
}

  position: relative;
  background-color: #161616;
  color: #FFFFFF;
  font-size: 32px;
  font-family: monospace;
  box-sizing: border-box;
  text-align: center;
`;

type TelemetryDictionary = {
  [key: string]: TelemetryData;
};

type TelemetryData = {
  limit: any; // Replace 'any' with the actual type of limitasf
  telemetry: string; // Replace 'any' with the actual type of cnvValue
  unit: string; // Replace 'any' with the actual type of unit
  live: string;
  mne: string;
  spacecraft: string;
};

type AppProps = {
  dbData: Field[];
  width: number;
  height: number;
  source: string;
  options: {
    text: string;                  // Corresponds to the 'addTextInput' option
    showSeriesCount: boolean;      // Corresponds to the 'addBooleanSwitch' option
    seriesCountSize: 'sm' | 'md' | 'lg';  // Corresponds to the 'addRadio' option
    X: number;           // Corresponds to the 'addNumberInput' option
    Y: number;
    Zoom: number;
  };
  influxData: ProcessedData;
  // ... other props if there are ay
};

const nodeTypes = {spacecraftModel: SpacecraftModel,sumBox1:SummaryBox1,sumBox2:SummaryBox2,htextbox:HTextBox,vmodeBox:VModeBox,chart: Chart,panel: Panel, textbox: Textbox}
const edgeTypes = {customEdge: CustomEdge}

const App: React.FC<AppProps> = ({dbData, source, width, height, options, influxData}) => {

  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const { guiValues } = useTimeRangeContext();
  // Use refs to always have the current values
  const pbTimeRef = useRef("0");
  const pbLoopRef = useRef(0);

  const onInit = useCallback((instance: ReactFlowInstance) => {
    setReactFlowInstance(instance);
  }, []);

  useEffect(() => {
    if (reactFlowInstance ) {
      //@ts-ignore
      reactFlowInstance.fitView();
    }
  }
  ,[width, height]);

  function isWithinDifference(unixTimestamp: number) {

    // Convert the Unix timestamp to a JavaScript Date object
    const timestampDate = new Date(unixTimestamp * 1000);

    // Get the current time
    const currentDate = new Date();

    // Calculate the difference in seconds
    const timeDifference = (currentDate.getTime() - timestampDate.getTime()) / 1000;

    // Compare and return
    if (timeDifference <= 30) {
        return "rgb(72, 200, 44)";
    } else if (timeDifference <= 43200) { // 43200 seconds = 12 hours 
        return "#CA51EC";
    } else {
        return "rgb(68, 169, 241)"; //blue -- stale data
    }
  }

  const getDataFromSource = useCallback((mne: string) => {
    const mneColumn = dbData.find(column => column.name === "mne");
    if (!mneColumn) {
      return { limit: null, cnvValue: null, unit: null, live: "", mne: mne  }; // mne column not found
    }
  
    const mneIndex = mneColumn.values.indexOf(mne);
    if (mneIndex === -1) {
      return { limit: null, cnvValue: null, unit: null, live: "", mne: mne  }; // mne not found
    }
  
    // Safely get the other columns
    const limitColumn = dbData.find(column => column.name === "limit");
    const cnvValueColumn = dbData.find(column => column.name === "cnvValue");
    const unitColumn = dbData.find(column => column.name === "units");
    const timeColumn = dbData.find(column => column.name === "tInsert");
  
    const limit = limitColumn ? limitColumn.values[mneIndex] : null;
    let cnvValue = cnvValueColumn ? cnvValueColumn.values[mneIndex] : null;
    let unit = unitColumn ? unitColumn.values[mneIndex] : null;
    let live = timeColumn ? isWithinDifference(timeColumn.values[mneIndex]) : "";

    // Append the degree symbol to the unit if the unit is 'C'
    if (unit === 'C' || unit === 'degC') {
      unit = '\u00B0C';
    }else if(unit === 'packets'){
      unit = 'P'
    }else if(unit === 'sec'){
      unit = 'S'
    }else if(unit === 'None' || unit === 'enum' || unit === 'N/A'){
      unit = null
    }else if(unit === 'bool'){
      unit = 'B'
    }else if(unit === 'count' || unit === 'counts' || unit === 'Counter'){
      unit = 'CNT'
    }else if(unit === 'volts'){
      unit = 'V'
    }else if(unit === 'amps'){
      unit = 'A'
    }
  
    // Special handling for 
    if (mne === 'cdh_supervisor_uptime_agg' || mne === 'CDH_SPOC_UPTIME_agg'.toLowerCase()) {
      cnvValue = (parseFloat(cnvValue) / 1000).toFixed(2);
      unit = 's';
    } else if (cnvValue !== null && !isNaN(parseFloat(cnvValue))) {
      // Check if cnvValue is a number and convert/round it if s
      const numValue = parseFloat(cnvValue);
      const [integerPart, decimalPart] = numValue.toString().split('.');

      // Convert to exponential form if the integer part is too long
        if (integerPart.length > 7) {
      cnvValue = numValue.toExponential(2);
        } else if (decimalPart) {
          // It's a float, parse and keep 2 decimal places
          cnvValue = numValue.toFixed(2);
        }
    }
  
    return { limit, cnvValue, unit, live, mne };
  },[dbData]);

  // Function to create the new dictionary
  const createTelemetryDictionary = useCallback(() => {
    const telemetryDict: Record<string, TelemetryData> = {};
  
    Object.entries(nameToMne).forEach(([name, mne]) => {
      let newMne = mne + "_agg"; // First try with _agg added
      let data = getDataFromSource(newMne.toLowerCase());
  
      // If the cnvValue is null or empty, try with the regular mne
      if (!data.cnvValue) {
        newMne = mne; // Use the regular mnemonic
        data = getDataFromSource(newMne.toLowerCase());
      }
  
      telemetryDict[name] = {
        limit: data.limit,
        mne: data.mne,
        telemetry: data.cnvValue,
        unit: data.unit,
        live: data.live,
        spacecraft: source ? source : ""
      };
    });
  
    return telemetryDict;
  }, [getDataFromSource, nameToMne, source]);
  

  const telemetryDictionary = createTelemetryDictionary();

  const [nodes, setNodes] = useState([
    { id: 'SpacecraftModel', type: 'spacecraftModel',parentNode:'', position:{x:575, y:250}, data:{'qx':telemetryDictionary['qx'],'qy':telemetryDictionary['qy'],'qz':telemetryDictionary['qz'],'qs':telemetryDictionary['qs']}},

    { id: 'SSA', type: 'textbox',parentNode:'', position:{x:2125, y:150}, data:{name:'Sun Sensor A',className:"", value:{'Temp':{'dbData':telemetryDictionary['FSS A Temp'],'fname':''},'Success Fit':{'dbData':telemetryDictionary['FSS A Success Fit'],'fname':''},"Geo quality":{"dbData":telemetryDictionary['FSS A Geometric quality'],'fname':''}}}},
    { id: 'SSB', type: 'textbox',parentNode:'', position:{x:25, y:150}, data:{name:'Sun Sensor B',className:"", value:{'Temp':{'dbData':telemetryDictionary['FSS B Temp'],'fname':''},'Success Fit':{'dbData':telemetryDictionary['FSS B Success Fit'],'fname':''},"Geo quality":{"dbData":telemetryDictionary['FSS B Geometric quality'],'fname':''}}}},
    { id: 'SSC', type: 'textbox',parentNode:'', position:{x:700, y:1450}, data:{name:'Sun Sensor C',className:"", value:{'Temp':{'dbData':telemetryDictionary['FSS C Temp'],'fname':''},'Success Fit':{'dbData':telemetryDictionary['FSS C Success Fit'],'fname':''},"Geo quality":{"dbData":telemetryDictionary['FSS C Geometric quality'],'fname':''}}}},
    { id: 'SSD', type: 'textbox',parentNode:'', position:{x:1400, y:1450}, data:{name:'Sun Sensor D',className:"", value:{'Temp':{'dbData':telemetryDictionary['FSS D Temp'],'fname':''},'Success Fit':{'dbData':telemetryDictionary['FSS D Success Fit'],'fname':''},"Geo quality":{"dbData":telemetryDictionary['FSS D Geometric quality'],'fname':''}}}},
 
    { id: 'STA', type: 'textbox',parentNode:'', position:{x:2125, y:500}, data:{name:'Star Tracker A',className:"", value:{'Pwr En':{'dbData':telemetryDictionary['ST A Power Enabled'],'fname':''},'Sync Bus Volt':{'dbData':telemetryDictionary['ST A Bus Volt'],'fname':''},"Sync Curr":{"dbData":telemetryDictionary['ST A Current'],'fname':''}, "Det Temp":{"dbData":telemetryDictionary['ST A Det Temp'],'fname':''}, "Meas Valid":{"dbData":telemetryDictionary['ST A Meas Valid'],'fname':''}, "Meas Time":{"dbData":telemetryDictionary['ST A Epoch of Last Succ Meas'],'fname':''}}}},
    { id: 'STB', type: 'textbox',parentNode:'', position:{x:25, y:500}, data:{name:'Star Tracker B',className:"", value:{'Pwr En':{'dbData':telemetryDictionary['ST B Power Enabled'],'fname':''},'Sync Bus Volt':{'dbData':telemetryDictionary['ST B Bus Volt'],'fname':''},"Sync Curr":{"dbData":telemetryDictionary['ST B Current'],'fname':''}, "Det Temp":{"dbData":telemetryDictionary['ST B Det Temp'],'fname':''}, "Meas Valid":{"dbData":telemetryDictionary['ST B Meas Valid'],'fname':''}, "Meas Time":{"dbData":telemetryDictionary['ST B Epoch of Last Succ Meas'],'fname':''}}}},

    { id: 'RWA', type: 'textbox',parentNode:'', position:{x:2125, y:1050}, data:{name:'Reaction Wheel A',className:"", value:{'Agular Rate':{'dbData':telemetryDictionary['RW A Agular Rate'],'fname':''},'Motor Temp':{'dbData':telemetryDictionary['RW A Motor Temp'],'fname':''},"Last Mode":{"dbData":telemetryDictionary['RW A Last Mode'],'fname':''}}}},
    { id: 'RWB', type: 'textbox',parentNode:'', position:{x:2125, y:1450}, data:{name:'Reaction Wheel B',className:"", value:{'Agular Rate':{'dbData':telemetryDictionary['RW B Agular Rate'],'fname':''},'Motor Temp':{'dbData':telemetryDictionary['RW B Motor Temp'],'fname':''},"Last Mode":{"dbData":telemetryDictionary['RW B Last Mode'],'fname':''}}}},
    { id: 'RWC', type: 'textbox',parentNode:'', position:{x:25, y:1050}, data:{name:'Reaction Wheel C',className:"", value:{'Agular Rate':{'dbData':telemetryDictionary['RW C Agular Rate'],'fname':''},'Motor Temp':{'dbData':telemetryDictionary['RW C Motor Temp'],'fname':''},"Last Mode":{"dbData":telemetryDictionary['RW C Last Mode'],'fname':''}}}},
    { id: 'RWD', type: 'textbox',parentNode:'', position:{x:25, y:1450}, data:{name:'Reaction Wheel D',className:"", value:{'Agular Rate':{'dbData':telemetryDictionary['RW D Agular Rate'],'fname':''},'Motor Temp':{'dbData':telemetryDictionary['RW D Motor Temp'],'fname':''},"Last Mode":{"dbData":telemetryDictionary['RW D Last Mode'],'fname':''}}}},

    { id: 'sumBox1', type: 'sumBox1',parentNode:'', position:{x:2700, y:100}, data:{name:'',time:'2024-09-23T23:00:00Z', value1:{'Ephemeris Det':{'dbData':telemetryDictionary['Ephemeris'],'fname':''},'Attitude Det':{'dbData':telemetryDictionary['Attitude'],'fname':''},"Eclipse Status":{"dbData":telemetryDictionary['Eclipse Status'],'fname':''}}, value2: {'Body Rate X':{'dbData':telemetryDictionary['Body Rate X'],'fname':''},'Body Rate Y':{'dbData':telemetryDictionary['Body Rate Y'],'fname':''},"Body Rate Z":{"dbData":telemetryDictionary['Body Rate Z'],'fname':''}, "Ang Mom X":{"dbData":telemetryDictionary['Ang Mom X'],'fname':''}, "Ang Mom Y":{"dbData":telemetryDictionary['Ang Mom Y'],'fname':''}, "Ang Mom Z":{"dbData":telemetryDictionary['Ang Mom Z'],'fname':''}}}},
    { id: 'sumBox2', type: 'sumBox2',parentNode:'', position:{x:2700, y:925}, data:{name:'', value1:{'Pos Cbix':{'dbData':telemetryDictionary['Pos Cbix'],'fname':''},'Pos Cbiy':{'dbData':telemetryDictionary['Pos Cbiy'],'fname':''},"Pos Cbiz":{"dbData":telemetryDictionary['Pos Cbiz'],'fname':''},'Vel Cbix':{'dbData':telemetryDictionary['Vel Cbix'],'fname':''},'Vel Cbiy':{'dbData':telemetryDictionary['Vel Cbiy'],'fname':''},'Vel Cbiz':{'dbData':telemetryDictionary['Vel Cbiz'],'fname':''}}, value2: {}}},

    { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:0, y: -100}, data:{name:'', VMode:telemetryDictionary['Vehicle Mode Agg'], playBackValue: {time: pbTimeRef.current, loop: pbLoopRef.current}}},

]);
 
  const createNodes = (telemetryDictionary: TelemetryDictionary) => {
    return [
      { id: 'SpacecraftModel', type: 'spacecraftModel',parentNode:'', position:{x:575, y:250}, data:{'qx':telemetryDictionary['qx'],'qy':telemetryDictionary['qy'],'qz':telemetryDictionary['qz'],'qs':telemetryDictionary['qs']}},

      { id: 'SSA', type: 'textbox',parentNode:'', position:{x:2125, y:150}, data:{name:'Sun Sensor A',className:"", value:{'Temp':{'dbData':telemetryDictionary['FSS A Temp'],'fname':''},'Success Fit':{'dbData':telemetryDictionary['FSS A Success Fit'],'fname':''},"Geo quality":{"dbData":telemetryDictionary['FSS A Geometric quality'],'fname':''}}}},
      { id: 'SSB', type: 'textbox',parentNode:'', position:{x:25, y:150}, data:{name:'Sun Sensor B',className:"", value:{'Temp':{'dbData':telemetryDictionary['FSS B Temp'],'fname':''},'Success Fit':{'dbData':telemetryDictionary['FSS B Success Fit'],'fname':''},"Geo quality":{"dbData":telemetryDictionary['FSS B Geometric quality'],'fname':''}}}},
      { id: 'SSC', type: 'textbox',parentNode:'', position:{x:700, y:1450}, data:{name:'Sun Sensor C',className:"", value:{'Temp':{'dbData':telemetryDictionary['FSS C Temp'],'fname':''},'Success Fit':{'dbData':telemetryDictionary['FSS C Success Fit'],'fname':''},"Geo quality":{"dbData":telemetryDictionary['FSS C Geometric quality'],'fname':''}}}},
      { id: 'SSD', type: 'textbox',parentNode:'', position:{x:1400, y:1450}, data:{name:'Sun Sensor D',className:"", value:{'Temp':{'dbData':telemetryDictionary['FSS D Temp'],'fname':''},'Success Fit':{'dbData':telemetryDictionary['FSS D Success Fit'],'fname':''},"Geo quality":{"dbData":telemetryDictionary['FSS D Geometric quality'],'fname':''}}}},
   
      { id: 'STA', type: 'textbox',parentNode:'', position:{x:2125, y:500}, data:{name:'Star Tracker A',className:"", value:{'Pwr En':{'dbData':telemetryDictionary['ST A Power Enabled'],'fname':''},'Sync Bus Volt':{'dbData':telemetryDictionary['ST A Bus Volt'],'fname':''},"Sync Curr":{"dbData":telemetryDictionary['ST A Current'],'fname':''}, "Det Temp":{"dbData":telemetryDictionary['ST A Det Temp'],'fname':''}, "Meas Valid":{"dbData":telemetryDictionary['ST A Meas Valid'],'fname':''}, "Meas Time":{"dbData":telemetryDictionary['ST A Epoch of Last Succ Meas'],'fname':''}}}},
      { id: 'STB', type: 'textbox',parentNode:'', position:{x:25, y:500}, data:{name:'Star Tracker B',className:"", value:{'Pwr En':{'dbData':telemetryDictionary['ST B Power Enabled'],'fname':''},'Sync Bus Volt':{'dbData':telemetryDictionary['ST B Bus Volt'],'fname':''},"Sync Curr":{"dbData":telemetryDictionary['ST B Current'],'fname':''}, "Det Temp":{"dbData":telemetryDictionary['ST B Det Temp'],'fname':''}, "Meas Valid":{"dbData":telemetryDictionary['ST B Meas Valid'],'fname':''}, "Meas Time":{"dbData":telemetryDictionary['ST B Epoch of Last Succ Meas'],'fname':''}}}},

      { id: 'RWA', type: 'textbox',parentNode:'', position:{x:2125, y:1050}, data:{name:'Reaction Wheel A',className:"", value:{'Agular Rate':{'dbData':telemetryDictionary['RW A Agular Rate'],'fname':''},'Motor Temp':{'dbData':telemetryDictionary['RW A Motor Temp'],'fname':''},"Last Mode":{"dbData":telemetryDictionary['RW A Last Mode'],'fname':''}}}},
      { id: 'RWB', type: 'textbox',parentNode:'', position:{x:2125, y:1450}, data:{name:'Reaction Wheel B',className:"", value:{'Agular Rate':{'dbData':telemetryDictionary['RW B Agular Rate'],'fname':''},'Motor Temp':{'dbData':telemetryDictionary['RW B Motor Temp'],'fname':''},"Last Mode":{"dbData":telemetryDictionary['RW B Last Mode'],'fname':''}}}},
      { id: 'RWC', type: 'textbox',parentNode:'', position:{x:25, y:1050}, data:{name:'Reaction Wheel C',className:"", value:{'Agular Rate':{'dbData':telemetryDictionary['RW C Agular Rate'],'fname':''},'Motor Temp':{'dbData':telemetryDictionary['RW C Motor Temp'],'fname':''},"Last Mode":{"dbData":telemetryDictionary['RW C Last Mode'],'fname':''}}}},
      { id: 'RWD', type: 'textbox',parentNode:'', position:{x:25, y:1450}, data:{name:'Reaction Wheel D',className:"", value:{'Agular Rate':{'dbData':telemetryDictionary['RW D Agular Rate'],'fname':''},'Motor Temp':{'dbData':telemetryDictionary['RW D Motor Temp'],'fname':''},"Last Mode":{"dbData":telemetryDictionary['RW D Last Mode'],'fname':''}}}},

      { id: 'sumBox1', type: 'sumBox1',parentNode:'', position:{x:2700, y:100}, data:{name:'',time:'2024-09-23T23:00:00Z', value1:{'Ephemeris Det':{'dbData':telemetryDictionary['Ephemeris'],'fname':''},'Attitude Det':{'dbData':telemetryDictionary['Attitude'],'fname':''},"Eclipse Status":{"dbData":telemetryDictionary['Eclipse Status'],'fname':''}}, value2: {'Body Rate X':{'dbData':telemetryDictionary['Body Rate X'],'fname':''},'Body Rate Y':{'dbData':telemetryDictionary['Body Rate Y'],'fname':''},"Body Rate Z":{"dbData":telemetryDictionary['Body Rate Z'],'fname':''}, "Ang Mom X":{"dbData":telemetryDictionary['Ang Mom X'],'fname':''}, "Ang Mom Y":{"dbData":telemetryDictionary['Ang Mom Y'],'fname':''}, "Ang Mom Z":{"dbData":telemetryDictionary['Ang Mom Z'],'fname':''}}}},
      { id: 'sumBox2', type: 'sumBox2',parentNode:'', position:{x:2700, y:925}, data:{name:'', value1:{'Pos Cbix':{'dbData':telemetryDictionary['Pos Cbix'],'fname':''},'Pos Cbiy':{'dbData':telemetryDictionary['Pos Cbiy'],'fname':''},"Pos Cbiz":{"dbData":telemetryDictionary['Pos Cbiz'],'fname':''},'Vel Cbix':{'dbData':telemetryDictionary['Vel Cbix'],'fname':''},'Vel Cbiy':{'dbData':telemetryDictionary['Vel Cbiy'],'fname':''},'Vel Cbiz':{'dbData':telemetryDictionary['Vel Cbiz'],'fname':''}}, value2: {}}},

      { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:0, y: -100}, data:{name:'', VMode:telemetryDictionary['Vehicle Mode Agg'], playBackValue: {time: pbTimeRef.current, loop: pbLoopRef.current}}},
  
    ]}

useEffect(() => {
      if (!guiValues.current.pbisInPlayBack) return;
    
      let isCancelled = false;
    
      const processData = async () => {
        // Initialize an empty object to store telemetry data
        let aggregatedData: TelemetryDictionary = {};
        const totalLoops = Object.keys(influxData).length;
    
        for (let i = 0; i < totalLoops; i++) {
          // Check if the process should stop
          if (!guiValues.current.pbisInPlayBack || isCancelled) {
            break;
          }

          const key = Object.keys(influxData)[i];
          const selectedData = influxData[key];
            pbTimeRef.current = key;
            const newValue = (i + 1)/totalLoops;
            pbLoopRef.current = newValue;

    
          // Loop through each item in selectedData and update the aggregatedData object
          selectedData.forEach(item => {
            // If the key already exists, update the telemetry value
            if (aggregatedData[item.name]) {
              if (item.cnvValue !== '') {
                aggregatedData[item.name].telemetry = item.cnvValue;
              }
            } else {
              aggregatedData[item.name] = {
                telemetry: item.cnvValue,
                mne: item.mne,
                limit: "",
                unit: "",
                spacecraft: "",
                live: "rgb(68, 169, 241)",
              };
            }
          });
    
          // Create nodes from the aggregatedData object
          const newNodes = createNodes(aggregatedData);
          setNodes(newNodes);
    
          // Log the current loop progress
          //console.log(`${i + 1}/${totalLoops}`);
    
          // Wait for 2 seconds before processing the next item
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
        guiValues.current.pbstopPlay()
        //console.log('Playback completed');
      };
    
      processData();
    
      // Cleanup function to cancel the loop if pbisInPlayBack changes
      return () => {
        isCancelled = true; 
      };
    }, [influxData, guiValues.current.pbisInPlayBack]);

    useEffect(() => {
      //console.log('Playback started asfafa', guiValues.pbisInPlayBack);
      if (guiValues.current.pbisInPlayBack) return;
      const telemetryDictionary1 = createTelemetryDictionary();
    
      const newNodes = createNodes(telemetryDictionary1); // Function to create nodes from telemetryDictionary
      setNodes(newNodes);
    }, [dbData, createTelemetryDictionary]);

  enum MarkerType {
    Arrow = 'arrow',
    ArrowClosed = 'arrowclosed',
  }

  const arrow = {type: MarkerType.ArrowClosed, color: 'white', orient: 'auto-start-reverse', width: 15, height: 15};

  const activeAnn = (enabled: TLM, name: string) => {

    if (enabled === undefined || enabled === null || !enabled) {
      return 8
    }

    const edgeColor = enabled && enabled.telemetry && enabled.telemetry.includes(name) ? 'green' : 'white';
    const opa = edgeColor === 'green' ? 9 : 8;
    return opa
  }

  const initialEdges = [
  {id: 'TtoG', source: 'Tele Subsystem',sourceHandle: 'source-right-1', target: 'Ground Subsystem',targetHandle:'target-right-1', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  {id: 'edge1', source: 'Ground Subsystem',sourceHandle: 'source-left-1', target: 'MOC',targetHandle:'target-right-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  
  {id: 'edge2', source: 'MOC',sourceHandle: 'source-right-1', target: 'Ground Subsystem',targetHandle:'target-left-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  {id: 'GtoT', source: 'Ground Subsystem',sourceHandle: 'source-right', target: 'Tele Subsystem',targetHandle:'target-right-1', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  //{id: 'edge4', source: 'Tele Subsystem',sourceHandle: 'source-left-1', target: 'CDH Subsystem',targetHandle:'target-right-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:100},
  {id: 'edge4', source: 'SP4T',sourceHandle: 'source-right', target: 'ScSketch',targetHandle:'target-left-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:100},
  {id: 'edge5', source: 'ScSketch',sourceHandle: 'source-left-1', target: 'LNA',targetHandle:'target-right', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:100},

  {id: 'edge6', source: 'S-Band Transponder',sourceHandle: 'source-right-1', target: 'SP2T',targetHandle:'target-left-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  {id: 'edge7', source: 'SP2T',sourceHandle: 'source-left-1', target: 'S-Band Transponder',targetHandle:'target-right-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},

  {id: 'edge8', source: 'SPOC',sourceHandle: 'source-right-2', target: 'S-Band Transponder',targetHandle:'target-left-2', type:'smoothstep',style: { stroke: 'white', strokeWidth:5}, markerEnd: arrow,zIndex:0},
  {id: 'edge9', source: 'S-Band Transponder',sourceHandle: 'source-left-2', target: 'SPOC',targetHandle:'target-right-2', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},

  {id: 'edge12', source: 'SPOC',sourceHandle: 'source-left-3', target: 'Supervisor',targetHandle:'target-right-3', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  {id: 'edge13', source: 'Supervisor',sourceHandle: 'source-right-3', target: 'SPOC',targetHandle:'target-left-3', type:'smoothstep',style: { stroke: 'white', strokeWidth:5}, markerEnd: arrow,zIndex:0},
  
  {id: 'edge14', source: 'SP2T',sourceHandle: 'source-top', target: 'HPA',targetHandle:'target-bottom', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  {id: 'edge15', source: 'HPA',sourceHandle: 'source-right', target: 'SP4T',targetHandle:'target-left-4', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},

  {id: 'edge21', source: 'MGA Rx',sourceHandle: 'source-left', target: 'LNA',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:activeAnn(telemetryDictionary['Ant State'], 'Mga'), data:{enabled:telemetryDictionary['Ant State'], name:'Mga'}},
  {id: 'edge22', source: 'LGA Rx 1',sourceHandle: 'source-left', target: 'LNA',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:activeAnn(telemetryDictionary['Ant State'], 'Lga1'), data:{enabled:telemetryDictionary['Ant State'], name:'Lga1'}},
  {id: 'edge23', source: 'LGA Rx 2',sourceHandle: 'source-left', target: 'LNA',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:activeAnn(telemetryDictionary['Ant State'], 'Lga2'), data:{enabled:telemetryDictionary['Ant State'], name:'Lga2'}},

  {id: 'edge24', source: 'LNA',sourceHandle: 'source-top', target: 'SP4T',targetHandle:'target-bottom', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},

  {id: 'edge25', source: 'SP4T',sourceHandle: 'source-left-2', target: 'SP2T',targetHandle:'target-right', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},

 ]; 

  const proOptions = { hideAttribution: true };

  return (
    <ReactFlowProvider>
      <div className={reactAppWrapper} style={{ height: height, width: width }}>
          <ReactFlow
          edges={initialEdges}
          onInit={onInit}
          edgeTypes={edgeTypes}
          nodes={nodes}
          nodeTypes={nodeTypes}
          proOptions={proOptions}
          //defaultViewport={{ x: option.X, y: option.Y, zoom: option.Zoom}}
          minZoom={0.1}
          fitView={true}
          />
        </div>
  
    </ReactFlowProvider>
  );

}

export default App;
