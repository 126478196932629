export type InnerDataValue = {
  telemetry: number;
  unit: string;
  limit: LimitProp;
  fname: string;
  mname: string;
  live: string;
  value_color: string;
  dbData: Dbdata;
  spacecraft: string;
};

export type InnerData = {
  [key: string]: InnerDataValue;
};

export interface LimitProp{
  rl: number | null;
  yl: number | null;
  yh: number | null;
  rh: number | null;
}

export type VisibleTooltips = { [key: string]: boolean };

export interface Dbdata{
  telemetry: number;
  unit: string;
  //limit: LimitProp;
  limit: string;
live: string;
mne: string;
spacecraft: string;
}

export interface TbProps {
    data: {
      name: string;
      value: InnerData;
      className: string;
      id: string;
      backgroundImage: string;
      current: TLM;
    };
}

export interface TLM {
  telemetry: string;
  unit: string;
  limit: string;
  live: string;
  mne: string;
}

export type Field = {
  name: string;
  type: string;
  labels?: { [key: string]: string };
  values: any[]; // Replace 'any' with a more specific type if possibl
};

export interface ProcessedData {
  [key: string]: { cnvValue: string, mne: string, name: string }[];
}

export interface DataObject {
  name: string;
  refId: string;
  length: number;
  fields: Field[];
  labels: { [key: string]: string };
}

export function lookUpName(mne: string) {
  for (const [key, value] of Object.entries(nameToMne)) {
    if (value === mne) {
      return key;
    }
  }
  return "NoName"; // Return null if the value is not found
}

export const nameToMne = {

  /* Sun Sensors */
  "FSS A Temp": "aocs_fss_a_celsius_temperature", /* Internal temperature */
  "FSS A Success Fit": "aocs_fss_a_vector_fit_quality", /*  */
  "FSS A Geometric quality": "aocs_fss_a_vector_geometry_quality", /* Geometric quality of most recent fit */
  "FSS B Temp": "aocs_fss_b_celsius_temperature", /* Internal temperature */
  "FSS B Success Fit": "aocs_fss_b_vector_fit_quality", /*  */
  "FSS B Geometric quality": "aocs_fss_b_vector_geometry_quality", /* Geometric quality of most recent fit */
  "FSS C Temp": "aocs_fss_c_celsius_temperature", /* Internal temperature */
  "FSS C Success Fit": "aocs_fss_c_vector_fit_quality", /*  */
  "FSS C Geometric quality": "aocs_fss_c_vector_geometry_quality", /* Geometric quality of most recent fit */
  "FSS D Temp": "aocs_fss_d_celsius_temperature", /* Internal temperature */
  "FSS D Success Fit": "aocs_fss_d_vector_fit_quality", /*  */
  "FSS D Geometric quality": "aocs_fss_d_vector_geometry_quality", /* Geometric quality of most recent fit */
  
  /* star trackers */
  "ST A Power Enabled":"eps_spoc_dio_st_a_pwr_en",
  "ST A Bus Volt":"aocs_st_a_syncbusvoltage",
  "ST A Current":"aocs_st_a_synccurrentsense",
  "ST A Det Temp":"aocs_st_a_hw_det_temp",
  "ST A Meas Valid":"aocs_st_a_measvalid",
  "ST A Epoch of Last Succ Meas":"aocs_st_a_meastime",
  "ST B Power Enabled":"eps_spoc_dio_st_b_pwr_en",
  "ST B Bus Volt":"aocs_st_b_syncbusvoltage",
  "ST B Current":"aocs_st_b_synccurrentsense",
  "ST B Det Temp":"aocs_st_b_hw_det_temp",
  "ST B Meas Valid":"aocs_st_b_measvalid",
  "ST B Epoch of Last Succ Meas":"aocs_st_b_meastime",
  
  /* Reaction Wheels */
  "RW A Agular Rate":"aocs_rw_a_hw_speed",
  "RW A Motor Temp":"aocs_rw_a_hw_temp0", /* Motor winding temperature */
  "RW A Last Mode":"aocs_rw_a_hw_lastmode", /* Last Moode */
  "RW B Agular Rate":"aocs_rw_b_hw_speed",
  "RW B Motor Temp":"aocs_rw_b_hw_temp0", /* Motor winding temperature */
  "RW B Last Mode":"aocs_rw_b_hw_lastmode", /* Last Moode */
  "RW C Agular Rate":"aocs_rw_c_hw_speed",
  "RW C Motor Temp":"aocs_rw_c_hw_temp0", /* Motor winding temperature */
  "RW C Last Mode":"aocs_rw_c_hw_lastmode", /* Last Moode */
  "RW D Agular Rate":"aocs_rw_d_hw_speed",
  "RW D Motor Temp":"aocs_rw_d_hw_temp0", /* Motor winding temperature */
  "RW D Last Mode":"aocs_rw_d_hw_lastmode", /* Last Moode */

  /* Summary 1 */
  "Ephemeris":'gnc_eph_est_output_ephemeris_determined', /* State of ephemeris, known or unknown (1 or 0) */
  "Attitude":'gnc_att_est_output_attitude_determined', /* Flag for valid attitude estimates */
  "Eclipse Status":'gnc_env_est_output_eclipsestate', /* current eclipse flag */

  /* Summary 2 */
  "Body Rate X":'gnc_att_est_output_omega_bx_agg', /*  */
  "Body Rate Y":'gnc_att_est_output_omega_by_agg', /*  */
  "Body Rate Z":'gnc_att_est_output_omega_bz_agg', /*  */
  "Ang Mom X":'gnc_mom_est_output_htotlowpass_bx', /*  */
  "Ang Mom Y":'gnc_mom_est_output_htotlowpass_by', /*  */
  "Ang Mom Z":'gnc_mom_est_output_htotlowpass_bz', /*  */

  /* Summary 3 */
  "Pos Cbix":'gnc_eph_est_output_pos_cbix', /*  */
  "Pos Cbiy":'gnc_eph_est_output_pos_cbiy', /*  */
  "Pos Cbiz":'gnc_eph_est_output_pos_cbiz', /*  */
  "Vel Cbix":'gnc_eph_est_output_vel_cbix', /*  */
  "Vel Cbiy":'gnc_eph_est_output_vel_cbiy', /*  */
  "Vel Cbiz":'gnc_eph_est_output_vel_cbiz', /*  */
    
  /* Sun Vector */
  "SunVecX":'gnc_env_est_output_usunest_bx', /* X component of unit vector from spacecraft body to sun */
  "SunVecY":'gnc_env_est_output_usunest_by', /*  */
  "SunVecZ":'gnc_env_est_output_usunest_bz', /*  */

  /* Quaternion */
  "qx":'gnc_att_est_output_q_i2bx_agg_7639', /* Quaternion component 1 */
  "qy":'gnc_att_est_output_q_i2by_agg_7639', /* Quaternion component 1 */
  "qz":'gnc_att_est_output_q_i2bz_agg_7639', /* Quaternion component 1 */
  "qs":'gnc_att_est_output_q_i2bs_agg_7639', /* Quaternion component 1 */
  
  "Vehicle Mode Agg":"state_spinand0_last_cmd_vehicle_mode_agg", /**/

}

export function determineColor(value: number, limit: LimitProp){
  if (limit.rl !== null && value < limit.rl){
    return 'red-limit';
  }else if (limit.rh !== null && value > limit.rh){
    return 'red-limit';
  }else if (limit.rl !== null && limit.yl !== null && value >= limit.rl && value < limit.yl) {
    return 'yellow-limit';
  }else if (limit.yh !== null && limit.rh !== null && value >= limit.yh && value < limit.rh) {
    return 'yellow-limit';
  }else if (limit.yl !== null && limit.yh !== null && value >= limit.yl && value < limit.yh) {
    return 'green-limit';
  }else if (limit.rl !== null && limit.rh !== null && value >= limit.rl && value < limit.rh){
    return 'green-limit';
  }else{
    return 'gray-limit';
  }
}

export function determineColorNew(name: string){

if (name === 'rl' || name === 'rh' || name === 'RH' || name === 'RL'){
  return 'red-limit';
}
else if (name === 'yl' || name === 'yh' || name === 'YH' || name === 'YL'){
    return 'yellow-limit';
}else{
  return 'gray-limit';
}
}