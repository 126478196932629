import React, { useState, useEffect } from 'react';

interface TimerProps {
  initialTime: number | null; // Time in seconds
  isVisible?: boolean; // New prop to control visibility
}

const Timer: React.FC<TimerProps> = ({ initialTime, isVisible=true }) => {
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
  const [color] = useState<string>('red');

  useEffect(() => {
    if (initialTime !== null) {
      setTimeRemaining(initialTime);
    }
  }, [initialTime]);

  useEffect(() => {
    if (timeRemaining === null) {return};

    const timer = setInterval(() => { 
      setTimeRemaining(prevTime => { 
        if (prevTime === null || prevTime <= 0) {
          clearInterval(timer);
          return 0;
        } 
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeRemaining]);

  const formatTime = (totalSeconds: number): string[] => {
    const days = String(Math.floor(totalSeconds / (24 * 60 * 60))).padStart(2, '0');
    const hours = String(Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60))).padStart(2, '0');
    const minutes = String(Math.floor((totalSeconds % (60 * 60)) / 60)).padStart(2, '0');
    const seconds = String(totalSeconds % 60).padStart(2, '0');
    return [days, hours, minutes, seconds];
  };

  const TimeUnit: React.FC<{ label: string; value: string }> = ({ label, value }) => (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ fontSize: '0.7em', color: 'white' }}>{label}</div>
      <div style={{ fontSize: '1.7em', color }}>{value}</div>
    </div>
  );

  const [days, hours, minutes, seconds] = timeRemaining !== null ? formatTime(timeRemaining) : ['00', '00', '00', '00'];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#000000',
        width: '750px',
        height: '200px',
      }}
    >
      <TimeUnit label="Days" value={days} />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <TimeUnit label="Hours" value={hours} />
        <div style={{ fontSize: '1.7em', color, alignSelf: 'flex-end' }}>:</div>
        <TimeUnit label="Minutes" value={minutes} />
        <div style={{ fontSize: '1.7em', color, alignSelf: 'flex-end' }}>:</div>
        <TimeUnit label="Seconds" value={seconds} />
      </div>
    </div>
  );
};

export default Timer;
