import React,{useState} from 'react'
import { Handle, Position } from 'reactflow';
import {InnerData, determineColorNew} from './utils';
import PlotlyChart from './PlotlyChart';
import * as scheme from './Scheme'; // Import your styles
import { css, cx } from '@emotion/css';
import copy from 'copy-to-clipboard';

type VisibleTooltips = { [key: string]: boolean };

type SummaryBox2Props = {
    data: {
        name: string;
        value1: InnerData;
        value2: InnerData;
    };
};

const SummaryBox2: React.FC<SummaryBox2Props> = ({data}) => {

  const handleCopyMneName = (mne: string) => {
    copy(mne);
  };

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});


  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  return (
    <div>
      <Handle type="target" position={Position.Top} id="target-top"/>
      <Handle type="target" position={Position.Left} id="target-left"/>

      <Handle type="target" position={Position.Right} id="target-right"/>

      <Handle type="target" position={Position.Bottom} id="target-bottom"/>

      <Handle type="source" position={Position.Top} id="source-top"/>
      <Handle type="source" position={Position.Left} id="source-left"/>

      <Handle type="source" position={Position.Right} id="source-right"/>
      <Handle type="source" position={Position.Bottom} id="source-bottom"/>

      <div className={cx(scheme.textboxContainer, css` 
      width:900px;
      font-size: 38px;`)}>

        <p>Ephermeris Summary</p>

        <div>
            <p>Active Ephemeris File</p>
            <p></p>
        </div>

        <div>
          <p>Spacecraft Ephemeris</p>
          <div className={css`
          display: flex;
          flex-direction: row;
          width: 900px;
          flex-wrap: wrap;
        `}>
          
            {data.value1 && Object.entries(data.value1).map(([key, innerData]) => (
              <div className={cx(scheme.tbValueContainer, css`
              display: flex;
              flex-direction: column;
              width: 295px;
              `)} key={key} >
                <div key={key} className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                  {key}:
                  {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                  <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
                </div>
                <div className={cx(scheme.tbValueSpanContainer, css`
              width: 275px;
              height: 55px;
              `)} onClick={() => handleValueChartClick(key)}>
                  <span 
                    className={cx(
                      scheme.value, 
                      css`color: ${innerData.dbData?.live}; line-height: 55px;`,
                      scheme.classMap[determineColorNew(innerData.dbData?.limit)], 
                    )}
                  >
                    {innerData.dbData?.telemetry}
                  </span>
                  {innerData.dbData && innerData.dbData.unit && <span className={cx(scheme.svgUnit, css`line-height:55px`)}>{innerData.dbData.unit}</span>}
                </div>
                {visibleLimitPops[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                      Close Plot
                    </button>
                  </div>}
              </div>
            ))}
          </div>
        </div>

        <div>
          <p>Delta Ephemeris</p>
          <div className={css`
          display: flex;
          flex-direction: row;
          width: 900px;
          flex-wrap: wrap;
        `}>
            {data.value1 && Object.entries(data.value1).map(([key, innerData]) => (
              <div className={cx(scheme.tbValueContainer, css`
              display: flex;
              flex-direction: column;
              width: 295px;
              `)} key={key} >
                <div key={key} className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                  {key}:
                  <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
                </div>
                <div className={cx(scheme.tbValueSpanContainer, css`
              width: 275px;
              height: 55px;
              `)} onClick={() => handleValueChartClick(key)}>
                  <span 
                    className={cx(
                      scheme.value, 
                      css`color: ${innerData.dbData?.live}; line-height: 55px;`,
                      scheme.classMap[determineColorNew(innerData.dbData?.limit)], 
                    )}
                  >
                    {innerData.dbData?.telemetry * 0}
                  </span>
                  {innerData.dbData && innerData.dbData.unit && <span className={cx(scheme.svgUnit, css`line-height:55px`)}>{innerData.dbData.unit}</span>}
                </div>
                {visibleLimitPops[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                      Close Plot
                    </button>
                  </div>}
              </div>
            ))}
          </div>
      </div>
      </div>
    </div>
  )
}

export default SummaryBox2;
